

import { create } from 'components/lib/npm'

const initialState = {
    themes: {
        "authPage": {
            form_width: '5000',
            'CommonAuthLayout-auth_card--border': '',
            'CommonAuthLayout-auth_card--border-radius': '6px',
            'CommonAuthLayout-auth_card--backGround': 'white',
            'CommonAuthLayout-auth_page_background--background-color': 'black',
            'auth_page_background_image': 'https://contents.hivebariatrics.com/local/DemoPractice-TESTEst-Prasctice/task_design/page_bg_image_1720241885.png',
            'SelectScreen-selectScreen_checkbox_label--font-size': '16px',
            'CommonAuthLayout-Auth_card_error--Color': 'red',
            'CommonAuthLayout-Auth_card_input--background_color': 'rgb(255, 255, 255)',
            'CommonAuthLayout-auth_card_title--font-size': '16px',
            'CommonAuthLayout-auth_card_title--font-weight': '500',
            'CommonAuthLayout-auth_card--title-color': 'rgb(24, 20, 20)',
            'CommonAuthLayout-auth_card--title-font-style': '',
            'SelectScreen-selectScreen_checkbox--background': 'white',
            'CommonAuthLayout-Auth_card_input--font-style': '',
            'CommonAuthLayout-Auth_card_input_placeholder--font-style': '',
            'CommonAuthLayout-auth_card_button--background-color': '#FFA91E',
            'CommonAuthLayout-auth_card_button--font-size': '18px',
            'CommonAuthLayout-auth_card_button--color': 'black',
            'CommonAuthLayout-auth_card_button--font-style': '',
            'SelectScreen-selectScreen_checkbox--color': 'rgb(223, 40, 40)',
            'CommonAuthLayout-auth_card--title-font-family': '',
            'CommonAuthLayout-auth_card_button--hover-background-color': 'rgb(202 138 4)',
            'CommonAuthLayout-Auth_card_input--font-family': '',
            'CommonAuthLayout-Auth_card_input_placeholder--color': 'black',
            'CommonAuthLayout-Auth_card_input_placeholder--font-family': '',
            'CommonAuthLayout-auth_card_button--hover-color': 'black',
            'CommonAuthLayout-auth_card_button--font-family': ''
        },
        "home": {
            'Header-header_logo--background': '#ffffff',
            'HiveMedical-main_dashboard--background': '#fdf6db',
            'HiveMedical-hive_medical__button--background-color': '#000000',
            'Header-header_main--background': '#ffffff',
            'HiveMedical-hive_medical__button--font-size': '10',
            'Common-hive_medical_section--background': '#ffffff',
            'HiveMedical-hive_medical__button--color': '#ffffff',
            'HiveMedical-hive_medical__button--font-family': 'Poppins, sans-serif',
            'ToDoNow-radio_group_container--background': '#67b120',
            'ToDoNow-radio_group_container--border-width': '1px',
            'error_message_color': '#fe0000',
            'YourTimeline-sidler_card--background': '#ffcd06',
            'HiveMedical-hive_medical__button--hover-background-color': '',
            'ToDoNow-radio_group_container--border-color': 'white',
            'HiveMedical-hive_medical__button--hover-color': 'white',
            'ToDoNow-radio_group_container--checked-color': '#ffffff',
            'Common-hive_medical_section_label--font-size': '16px',
            'Common-hive_medical_section_title--font-size': '30px',
            'button_google_font_style': '',
            'Common-hive_medical_section_label--color': '#000000',
            'Common-hive_medical_section_label--font-style': 'Poppins, sans-serif',
            'Common-hive_medical_section_title--color': '#000000',
            'Common-hive_medical_section_title--font-style': 'Poppins-Italic',
            'YourTimeline-hive_medical_sub_title_label--font-size': '10',
            'YourTimeline-hive_medical_sub_title_label--color': '#000000',
            'YourTimeline-hive_medical_sub_title_label--font-style': 'Poppins, sans-serif',
            'Common-hive_medical_section_label--font-family': '',
            'Common-hive_medical_section_title--font-family': '',
            'YourTimeline-hive_medical_sub_title_label--font-family': '',
            'form_width': undefined
        }
    },

    auth_page_background_image: null,
}

const useStylesStore = create((set, get) => ({

    ...initialState,
    setState: (nextState) => set(() => ({ ...nextState })),
    getState: () => get(),

}))

export default useStylesStore