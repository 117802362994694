import { create } from "components/lib/npm";

const initialSocketState = {
    allEventsError: null,
    retrieveTaskResponse: null,
    healthCheckResponse: null,
    taskCompleteReqByPatientResponse: null,
    submitDateDetailsResponse: null,
    submitSitesDetailsResponse: null,
    submitSiteOrProviderDetailsResponse: null,
}

const useSocketResponseStore = create((set, get) => ({
    ...initialSocketState,
    setSocketResponseState: (nextState) => set({ ...nextState }),
    getState: () => get()
}));

export default useSocketResponseStore