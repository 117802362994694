import { ring_loader } from "assets/utils/images";
import { Button } from "../button";


function CustomButton({
    type = "submit",
    className = "",
    onClick,
    formGroup = '',
    disabled = false,
    BtnLoader = false,
    isLoading = false,
    value = 'Submit',
    ...rest
}) {
    return (
        <div className={`relative w-full max-w-[204px] flex align-center justify-center ${formGroup}`}>
            <Button
                type={type}
                className={`w-full font-bold text-black py-3 px-0 rounded-full hover:bg-yellow-600 transition duration-300 ease-in-out capitalize ${className}`}
                onClick={onClick}
                disabled={disabled || BtnLoader}
                {...rest}
            >
                {value}
            </Button>
            {BtnLoader && <span className="absolute w-[30px] h-[30px] top-[3px] right-[9px] "><img src={ring_loader} alt="btn_loader" /></span>}
        </div>
    );
}


export default CustomButton;