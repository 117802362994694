
import FontImporter from "components/FontImporter";
import { useEffect } from "components/lib/react-npm"
import useStylesStore from "store/styles/useStylesStore";


export default function AllStylesProvider({ children }) {
    const { setState, themes } = useStylesStore();
    const fonts = ['Rubik+Glitch+Pop', 'serif', 'Noto+Sans+JP', 'Playwrite+HU', 'Roboto', 'Open+Sans', ' Rubik Glitch Pop', 'Lato']; // Example fonts

    useEffect(() => {

        setState({
            auth_page_background_image: 'https://contents.hivebariatrics.com/local/DemoPractice-TESTEst-Prasctice/task_design/page_bg_image_1720241885.png',
        });

        Object.entries(themes.authPage).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, `${value}`);
        });
        Object.entries(themes.home).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, `${value}`);
        });

    }, [themes])

    return (
        <>
            <FontImporter fonts={fonts} />
            {children}
        </>
    )
}