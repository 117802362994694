import useSocketResponseStore from 'store/soket-response-store/setSocketResponseState';

const { setSocketResponseState } = useSocketResponseStore.getState();

export const events = [


    {
        name: 'eventHelper',
        handler: (data) => {
            console.log("eventHelper------->", data);
        },
    },

    {
        name: 'retrieveTask',
        handler: (data) => {
            console.log("retrieveTask----->", data);
            setSocketResponseState({ retrieveTaskResponse: data });
        },
    },

    {
        name: 'healthCheck',
        handler: (data) => {
            setSocketResponseState({ healthCheckResponse: data });

        },
    },
    {
        name: 'taskCompleteReqByPatient',
        handler: (data) => {
            setSocketResponseState({ taskCompleteReqByPatientResponse: data });
        },
    },
    {
        name: 'submitDateDetails',
        handler: (data) => {
            setSocketResponseState({ submitDateDetailsResponse: data, });
        },
    },

    {
        name: 'submitSiteOrProviderDetails',
        handler: (data) => {
            setSocketResponseState({ submitSiteOrProviderDetailsResponse: data });
        },
    }
];
