import React, { useState } from 'react';
import { hive_logo, menu_icon } from 'assets/utils/images';
import { useNavigate } from "components/lib/react-npm";
import CustomButton from 'components/ui/custom-button/CustomButton';
import browserStorageService from 'services/shared/browserStorage.service';
import useAuthenticationStore from 'store/authentication/useAuthenticationStore';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu';

export const Header = () => {
    const { setState } = useAuthenticationStore();

    const [isOpen, setIsOpen] = useState(false)

    const navigate = useNavigate();
    const slug = browserStorageService.getSession('slug')

    const logOutHandler = () => {
        setState({ auth: null })
        browserStorageService.removeCookie('_token')
        navigate(`/${slug}`);
    };


    return (
        <header className="header_main">
            <div className="header_logo">
                <img src={hive_logo} alt="Company Logo" />
            </div>
            <div className='flex flex-col gap-4 pr-5 side_menu'>
                <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
                    <DropdownMenuTrigger className='outline-none cursor-pointer'>
                        <div className={`${isOpen ? 'change' : ''} header_menu`}>
                            <img src={menu_icon} alt="Menu Icon" />
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="mr-5 w-[200px]">
                        <DropdownMenuItem className='focus:bg-transparent'>
                            <CustomButton value={'Log Out'} onClick={logOutHandler} className="schedule_section-button hive_medical__button p-1" />
                        </DropdownMenuItem>
                        <DropdownMenuItem className='focus:bg-transparent'>
                            <CustomButton value={'Text Us'} className="schedule_section-button hive_medical__button p-1" />
                        </DropdownMenuItem>
                        <DropdownMenuItem className='focus:bg-transparent'>
                            <button className="contact-button">Have a Question? </button>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </header>
    );
};
