import { Header } from "pages/hader/hader"

function WrapperLayout({ children }) {

    return (
        <div className="main_dashboard">
            <Header />
            <main className="hive_medical_main lg:gap-6 md:gap-3 gap-5 flex flex-wrap">
                {children}
            </main>
        </div>

    )
}

export default WrapperLayout