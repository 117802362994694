
import { memo, Suspense, Navigate, Outlet, useLocation } from "components/lib/react-npm";
import { ErrorBoundary } from 'components/lib/npm';
import ErrorBoundryFallback from 'components/error-boundry-fallback/ErrorBoundryFallback';
import useAuthenticationStore from "store/authentication/useAuthenticationStore";
import { HelmetProvider } from 'react-helmet-async';
import Loader from "components/ui/loader/Loader";
import useStylesStore from "store/styles/useStylesStore";
import { background_img } from "assets/utils/images";


const PublicRouter = ({ title, path }) => {

    const location = useLocation();
    const { auth } = useAuthenticationStore();

    const rootPath = "/home";

    return auth
        ? <Navigate to={rootPath} state={{ from: location }} replace />
        : (
            <HelmetProvider context={{}}>
                <title>{title}</title>
                <ErrorBoundary FallbackComponent={ErrorBoundryFallback}>
                    <Suspense fallback={<PageLader />}>
                        <Outlet />
                    </Suspense>
                </ErrorBoundary>
            </HelmetProvider>
        )
};

const PageLader = () => {
    const { auth_page_background_image } = useStylesStore();

    return (
        <div className="flex flex-col items-center justify-center min-h-screen auth_page_background"
            style={{ backgroundImage: `url(${auth_page_background_image ?? background_img})` }}>
            <Loader />
        </div>
    )
}
export default memo(PublicRouter);


